@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    margin: 0;
    padding: 0;
}

.topToolbar-div {
  position: fixed;
  height: 60px;
  top: 0px;
}

.bottomList-div {
  position: fixed;
  width: 320px;
  height: calc(85vh - 60px);
  top: 60px;
  overflow: auto;
}

.rightListNoScroll-div {
  position: fixed;
  width: 30vw;
  height: 85vh;
  left: 70vw;
  top: 64px;
}

.rightList-div {
  position: fixed;
  width: 30vw;
  height: 85vh;
  left: 70vw;
  top: 64px;
  overflow: auto;
}

.leftMain-div {
  position: fixed;
  width: 70vw;
  height: calc(85vh - 100px);
  left: 0;
  top: 64px;
  padding: 4;
}

.rightFilters-div {
  position: fixed;
  width: 70vw;
  height: 85vh;
  left: 30vw;
  top: 64px;
  overflow: auto;
}

.leftCourt-div {
  position: fixed;
  width: 320px;
  height: 85vh;
  left: 0;
  top: 64px;
  padding: 4;
}

.scrollable-div {
    overflow: auto;
    margin: 8px;
    text-align: justify;
}

.same-line {
    height: 10px; /*childrens it's all absolute position, so must set height*/
    position: relative;
}
.same-line div{
    display: inline-block;
    position: absolute;
}
.on-the-left{
 left:0px;
}
.on-the-center{
    left: 0%;
    right: 0%;
    text-align: center;
}
.on-the-right{
   right: 0px;
}

.option {
  margin-top: 0.3rem;
  vertical-align: text-bottom;
}

.result {
  margin-top: 1rem;
}

.options-list,
.total {
  width: 30%;
  margin: 0 auto;
}

.options-list {
  list-style: none;
  padding: 0;
}

.options-list li {
  margin-bottom: 0.5rem;
}

.options-list-item {
  display: flex;
  justify-content: space-between;
}

/* .options-list li:last-child {
  border-top: 1px solid #ccc;
  margin-top: 1rem;
  padding-top: 1rem;
} */

.options-list-item label {
  vertical-align: text-center;
  margin-left: 0.4rem;
}

.Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }
  
  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }
  
  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }
  
  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }
  
  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }
  
  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.disabled {
    cursor: not-allowed;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }